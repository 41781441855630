import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import testJson from '../test/testJson/test.json';
import { useState } from 'react'


function Regist_contractor() {
    function handleSubmit(e) {
        e.preventDefault();
        console.log('You clicked submit.');
        // alert(state.roomNumber);
    }
    var lockInfo = null;
    function setLockInfo(){
        lockInfo = testJson[0];
    }

    var handleChange = (event) => {
        let buff = event.target.value;//入力された文字列
        // setState({roomNumber: buff});
    }




    function constructor(props) {
        // super(props);
        setLockInfo();
        // state=lockInfo;
    }
    

        setLockInfo();
        return (
            <div>
                契約者新規登録<br></br>
                <form>
                    <ul>
                        <li>
                            <label class="li_left">契約企業名</label>
                        </li>
                        <li>
                            <input class="textbox" placeholder="Type here..." type="text" disabled/>
                        </li>
                        <li>
                            <label class="li_left">住所</label>
                        </li>
                        <li>
                            <input class="textbox" placeholder="Type here..." type="text" disabled/>
                        </li>
                        <li>
                            <label class="li_left">電話番号</label>
                        </li>
                        <li>
                            <input class="textbox" placeholder="Type here..." type="text" disabled/>
                        </li>
                        <li>
                            <label class="li_left">メールアドレス</label>
                        </li>
                        <li>
                            <input class="textbox" placeholder="Type here..." type="text" disabled/>
                        </li>
                        <li>
                            <label class="li_left">その他情報</label>
                        </li>
                        <li>
                            <input class="textbox" placeholder="Type here..." type="text" disabled/>
                        </li>
                        <li>
                        <br></br>
                        <input class="button" type="submit" value="登録" />
                        </li>
                    </ul>
                </form>


            </div>
        );
}
 
export default Regist_contractor; 