import React, { useEffect, useState, useRef } from 'react';
import { Link,useLocation } from 'react-router-dom';
import '../App.css';
import testJson from '.././test/testJson/setting_doorlock_test.json';
import testJson0 from '.././test/testJson/setting_doorlock_test0.json';
import testJson1 from '.././test/testJson/setting_doorlock_test1.json';
import Amplify, {Auth} from 'aws-amplify';

Amplify.configure({
    Auth: {
        region: 'ap-northeast-1',
        userPoolId: 'ap-northeast-1_Kfr3UKy7c',
        userPoolWebClientId: '5m1up4rgqb511ed038qip90g3t',
    }
});


// const getIdToken = async () =>{
//     try{
//         Auth.currentSession().then((data) => {
//             console.log(`token: ${data.getIdToken().getJwtToken()}`);
//             return data.getIdToken().getJwtToken()
//           });
//     }
//     catch(e){
//         return console.log('Not signed in');
//     }    
// }
const getIdToken = () =>{
    return new Promise((resolve, reject) => {
        try{
            Auth.currentSession().then((data) => {
                console.log(`token: ${data.getIdToken().getJwtToken()}`);
                return resolve(data.getIdToken().getJwtToken());
              });
        }
        catch(e){
            console.log('Not signed in');
            return reject('ID取得失敗')
        }  
    })
  
}

const fetchUserCodes =  async (setData) => {
    const idToken = getIdToken();
    const obj = {"user_code": "123456"};
    const method = "PUT";
    const body = JSON.stringify(obj);
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization':idToken
      };
    const res = await fetch('https://dev-api.iot.leadsecurity.co.jp/console/v1/devices/user-codes/8CADC332-8C5A-4F38-87C8-21DEBAB6FEB8',
    {mode:"cros", method, headers, body})
    .then((res)=> res.json())
    .catch(err => alert(err));

    const data = await res.json()
    setData(data.data)
  }

function Setting_doorlock() {

    // var idToken=async () =>(await Auth.currentSession()).getIdToken().getJwtToken();
    // const user = await Auth.currentAuthenticatedUser();
    // const idToken = user.signInUserSession.idToken.jwtToken;

    // const idToken = getIdToken();
    var idToken="no token";

    const a = 1;
    // idToken=Auth.currentSession().getIdToken().getJwtToken()
    // idToken=Auth.currentSession().getIdToken().getJwtToken()
    // idToken=Auth.currentSession().getAccessToken().getJwtToken()

    // Auth.currentSession().then(data => {
    //     idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
    //     idToken="b"
    //     console.log('idトークンに代入');
    // });
    // 試験用
    const [count, setCount] = useState(0);
    //ここまで

    const isFirstRender = useRef(false)
    const isFirstRenderOpen = useRef(false)
    const isFirstRenderClose = useRef(false)
    const isFirstRenderPinSend = useRef(false)
    const location = useLocation();
    const [lockInfo, setLockInfo] = useState(testJson0);
    const [closeInfo, setCloseInfo] = useState(0);
    const [openInfo, setOpenInfo] = useState(0);
    const [pinSend, setPinSend] = useState(0);
    const [testInfo, setTestInfo] = useState();
    // useEffect(() => {
    //     async function fetchLockInfoAsync() {
    //         // const resp = await fetchLockInfoAsync();
    //         const resp = await testJson;
    //         setLockInfo(resp);
    //     }
    //     fetchLockInfoAsync();
    // },[]);

    useEffect(() => {
        isFirstRender.current = true
        isFirstRenderOpen.current = true
        isFirstRenderClose.current = true
        isFirstRenderPinSend.current = true

    },[])

    function handleSubmit(e) {
        e.preventDefault();
        console.log('You clicked submit.');
    }
    // function setLockInfo(){
    //     lockInfo = testJson;
    // }

    const handleChange = (event) => {
        let buff = event.target.value;
        // setState({roomNumber: buff});
    }
    const [pinCode, setPinCode] = useState('')
    const pinCodeHandleChange = (e) => {
        setPinCode(() => e.target.value)
    }

    // function constructor(props) {
    //     setLockInfo();
    //     state=lockInfo;
    // }
    // setLockInfo(testJson);

    // 試験用
    useEffect(()=>{
        if(isFirstRender.current){
            isFirstRender.current = false;
        }
        else{
            console.log('useEffectが実行されました')
        }
      },[count])

    // 表示する鍵情報が更新されるたびに呼ばれる
    useEffect(()=>{
        if(isFirstRender.current){
            isFirstRender.current = false;
        }
        else{
            console.log('鍵情報が更新されました')
        }    
    },[lockInfo])

    // 解錠制御を押すたびに呼ばれる
    useEffect(()=>{

        if(isFirstRenderOpen.current){
            isFirstRenderOpen.current = false;
        }
        else{
            getIdToken()
            .then(result => {
                console.log("ID取得成功："+ result)
                const obj = {
                    "command": "lock",
                    "parameter": "unlock"
                };
                const method = "PUT";
                const body = JSON.stringify(obj);
                const headers = {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization':result
                  };
                fetch('https://dev-api.iot.leadsecurity.co.jp/console/v1/devices/locks/CCBF1D6D-9FE8-4E31-8147-CDCAFA78C610',
                // {mode:"cros", method, headers, body})
                {method:method, headers:headers, body:body, mode:"cors"})
                .then((res)=> res.json())
                  .then(data => {
                    // setLockInfo(data)
                    // 今は試験用にテストデータ
                    setLockInfo(testJson)
                    setTestInfo(data)
                    console.log("fetch実行完了")
                  },[])
                  .catch(err => {
                    console.log("fetch失敗")
                    alert(err)
                    })
            }).catch(error => {
                console.error("IDトークン取得失敗")
            }
            )
        }
    },[openInfo])

    // 施錠制御を押すたびに呼ばれる
    useEffect(()=>{
        if(isFirstRenderClose.current){
            isFirstRenderClose.current = false;
        }
        else{
            getIdToken()
            .then(result => {
                const obj = {
                    "command": "lock",
                    "parameter": "lock"
                };
                const method = "PUT";
                const body = JSON.stringify(obj);
                const headers = {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization':result
                  };
                fetch('https://dev-api.iot.leadsecurity.co.jp/console/v1/devices/locks/CCBF1D6D-9FE8-4E31-8147-CDCAFA78C610',
                // {mode:"cros", method, headers, body})
                {method:method, headers:headers, body:body, mode:"cors"})
                .then((res)=> res.json())
                  .then(data => {
                    // setLockInfo(data)
                    // 今は試験用にテストデータ
                    setLockInfo(testJson)
                    setTestInfo(data)
                    console.log("fetch実行完了")
                  },[])
                  .catch(err => {
                    console.log("fetch失敗")
                    alert(err)
                    })
            }).catch(error => {
                console.error("IDトークン取得失敗")
            }
            )
        }
    },[closeInfo])

    // PINコード送信を押すたびに呼ばれる
    useEffect(()=>{
        if(isFirstRenderPinSend.current){
            isFirstRenderPinSend.current = false;
        }
        else{
            getIdToken()
            .then(result => {
                console.log("送信するpinコード："+pinCode)
                console.log("ID取得成功："+ result)
                const obj = {"user_code": pinCode};
                const method = "PUT";
                const body = JSON.stringify(obj);
                const headers = {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization':result
                };
                fetch('https://dev-api.iot.leadsecurity.co.jp/console/v1/devices/user-codes/8CADC332-8C5A-4F38-87C8-21DEBAB6FEB8',
                {method:method, headers:headers, body:body, mode:"cors"})
                .then((res)=> res.json())
                  .then(data => {
                    // setLockInfo(data)
                    // 今は試験用にテストデータ
                    setLockInfo(testJson)
                    setTestInfo(data)
                    console.log("fetch実行完了")
                  },[])
                  .catch(err => {
                    console.log("fetch失敗")
                    alert(err)
                    })
            }).catch(error => {
                console.error("IDトークン取得失敗")
            }
            )
        }
    },[pinSend])
    
    return(
            <div>
                電子錠{location.state.text}<br></br>
                <form>
                    <ul>
                        <li>
                            <label class="li_left">情報取得日時</label>
                        </li>
                        <li>
                            <input class="textbox" placeholder={lockInfo.lock.time} type="text" disabled/>
                            <input class="button" type="submit" value="更新" />
                        </li>
                        <li>
                        </li>
                    </ul>
                </form>
                <form>
                    <ul>
                        <li>
                            <label class="li_left">Serial Number</label>
                            <input class="textbox" placeholder={lockInfo.serial_number} type="text" disabled/>
                        </li>
                        <li>
                            <label class="li_left">DSKコード</label>
                            <input class="textbox" placeholder={lockInfo.dsk} type="text" disabled/>
                        </li>
                        <li>
                            <label class="li_left">接続ゲートウェイ</label>
                            <input class="textbox" placeholder={lockInfo.gw_serial_number} type="text" disabled/>
                        </li>
                        <li>
                            <label class="li_left">設置フロア</label>
                            <input class="textbox" placeholder={lockInfo.floor} type="text"/>
                        </li>
                        <li>
                            <label class="li_left">設置部屋番号</label>
                            <input class="textbox" placeholder={lockInfo.name} type="text"  onChange={handleChange}/>
                            <input class="button" type="submit" value="登録" onClick={event=>handleSubmit(event)}/>
                        </li>
                    </ul>
                </form>
                <ul>
                    <li>
                        <label class="li_left">施錠制御</label>
                        <button onClick={() => setCloseInfo(closeInfo+1)}>施錠実行</button>
                        {/* <input class="button" type="submit" value="施錠実行" /> */}
                    </li>
                </ul>
                <ul>
                    <li>
                        <label class="li_left">解錠制御</label>
                        {/* <input class="button" type="submit" value="解錠実行" /> */}
                        <button onClick={() => setOpenInfo(openInfo+1)}>解錠実行</button>
                    </li>
                </ul>
                <ul>
                    <li>
                        <label class="li_left">PINコード送信</label>
                        <input class="textbox" type="text" value={pinCode} onChange={pinCodeHandleChange}/>
                        <button onClick={() => setPinSend(pinSend+1)}>送信</button>
                    </li>
                </ul>
                <ul>
                    <li>
                    <label class="li_left">電池残量</label>
                    <input class="textbox" placeholder={lockInfo.battery.status} type="text" disabled/>
                    </li>
                    <li>
                    <label class="li_left">異常通知</label>
                    <input class="textbox" placeholder={lockInfo.deadbolt.locking} type="text" disabled/>
                    </li>
                    <li>
                    <label class="li_left">状態表示</label>
                    <input class="textbox" placeholder={lockInfo.lock.status} type="text" disabled/>
                    </li>
                    <li>
                    <label class="li_left">RSSI（電波強度）</label>
                    <input class="textbox" placeholder={lockInfo.rssi.value} type="text" disabled/>
                    </li>
                </ul>
                <h2>Count: { count }</h2>
                <button onClick={() => setCount(count+1)}>施錠実行</button>
                ID：{testInfo}
                {/* <ul>
                {
                    testInfo.map(post => <li key={post.id}>{post.title}</li>)
                }
            </ul> */}
            </div>
        
    )
}
 
export default Setting_doorlock; 