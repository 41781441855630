import React from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import '../App.css';
import testJson from '.././test/testJson/hotellistTest.json';
import { useState } from 'react'


function Hotellist() {
    const location = useLocation();
    function handleSubmit(e) {
        e.preventDefault();
        console.log('You clicked submit.');
        // alert(state.roomNumber);
    }
    var hotelsInfo = null;
    function setHotelsInfo(){
        hotelsInfo = testJson;
    }

    var handleChange = (event) => {
        let buff = event.target.value;
        // setState({roomNumber: buff});
    }

    function constructor(props) {
        // super(props);
        setHotelsInfo();
        // state=hotelsInfo;
        handleClick = handleClick.bind(this)

    }
    function getHotelsLinks(state){
        var list = [];
        for(var i in state.hotels){
            list.push(<li>
                <button class="link_style_button" onClick={handleClick.bind(this, state.hotels[i].name, state.hotels[i].hotel_id)}>{state.hotels[i].name}</button>
                </li>);
        }
        return list;
    }
    const history = useHistory();
    function handleClick(hotelData, hotelId){
        history.push({
          pathname: "/device_list",
          state: { text: hotelData, id: hotelId }
      });
    }

        // ホテル情報の取得
        // setHotelsInfo();
        // ホテル一覧のリンクを取得
        var links = getHotelsLinks(testJson);
        return (
            <div>
                <p>{location.state.text}</p><br></br>
                <form>
                    <ul>
                        <li>
                            <label class="li_left">ホテル一覧</label>
                            <input class="button" type="submit" value="新規登録" />
                        </li>
                        {/* ここからホテル一覧のリンクのリストが表示される */}
                        {links}
                        {/* ここまでホテル一覧のリンクのリストが表示される */}
                    </ul>
                </form>

            </div>
        );
    
}
 
export default Hotellist; 