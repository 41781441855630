import React from 'react';
import { Link, useLocation, useHistory  } from 'react-router-dom';
import '../App.css';
import testJson from '.././test/testJson/test.json';
import { useState } from 'react'
import Amplify, { Auth } from 'aws-amplify';

// Amplifyの設定
Amplify.configure({
    Auth: {
      // リージョン
      region: 'ap-northeast-1',
      // ユーザプールのID
      userPoolId: 'ap-northeast-1_Kfr3UKy7c',
      // アプリクライアントID
      userPoolWebClientId: '5m1up4rgqb511ed038qip90g3t',
    }
  });
async function signUp (userName, password) {
    try {
      await Auth.signUp({
        username: userName, 
        password: password
      });
    } catch (error) {
      console.log(error);
    }
  };

function Regist_user() {
    const location = useLocation();
    function handleSubmit(e) {
        e.preventDefault();
        console.log('You clicked submit.');
        // alert(state.roomNumber);
    }
    var lockInfo = null;
    function setLockInfo(){
        lockInfo = testJson[0];
    }

    var handleChange = (event) => {
        let buff = event.target.value;
        // setState({roomNumber: buff});
    }
    var usernameHandleChange = (event) => {
        // setState({username: event.target.value});
    }
    var passwordHandleChange = (event) => {
        // setState({password: event.target.value});
    }

    function constructor(props) {
        // super(props);
        setLockInfo();
        // state=lockInfo;
        // state = {username: ''};
        // state = {password: ''};
        handleClick = handleClick.bind(this);

    }
    function handleClick(username, password){
        signUp(username, password);
    }

        setLockInfo();
        return (
            
            <div>
                ユーザー新規登録<br></br>
                <form>
                    <ul>
                        <li>
                            <label class="li_left">ユーザー新規登録</label>
                        </li>
                        <li>
                            <input class="textbox" placeholder="Type here..." type="text" />
                        </li>
                        <li>
                            <label class="li_left">メールアドレス</label>
                        </li>
                        <li>
                            <input class="textbox" placeholder="Type here..." type="text" />
                        </li>
                        <li>
                            <label class="li_left">権限</label>
                        </li>
                        <br></br>
                        <li>
                            <input class="" type="checkbox"/>管理者
                            <br></br>
                            <input class="" type="checkbox"/>社内利用者
                            <br></br>
                            <input class="" type="checkbox"/>設置工事者
                        </li>
                        <li>
                        <label class="li_left">利用ホテル(設置工事者のみ)</label>
                        <input class="button" type="submit" value="ホテル追加" />
                        <br></br>
                        <br></br>
                        <input class="textbox" placeholder="エスリードホテル日本橋" type="text" disabled/>
                        <br></br>
                        {/* <input type="text" value={location.state.username} onChange={usernameHandleChange} /> */}
                        {/* <input type="password" value={location.state.password} onChange={passwordHandleChange} /> */}
                        <button onClick={() => alert(location.state.username + location.state.password)}>値の確認</button>
                        {/* <button onClick={handleClick.bind(this, location.state.username, location.state.password)}>登録</button> */}
                        <input class="button" type="submit" value="登録" />
                        </li>
                    </ul>
                </form>


            </div>
        );
}
 
export default Regist_user; 