import React from 'react';
import { Link, useLocation, useHistory  } from 'react-router-dom';
import '../App.css';
import testJson from '.././test/testJson/device_list_test.json';
import { useState } from 'react'
import { Storage } from 'aws-amplify';

function Device_list() {
    const history = useHistory();
    var hotelName = "";
    const location = useLocation();

    // location.stateが無い時（直接URLを叩いた時）はホームへ遷移
    if(!location.state){
        history.push({
            pathname: "/",
        });
    }
    else{
        hotelName = location.state.text;
    }
    function handleSubmit(e) {
        e.preventDefault();
        console.log('You clicked submit.');
        // alert(state.name);
    }
    let lockInfo = null;
    function setLockInfo(){
        lockInfo = testJson;
    }

    const handleChange = (event) => {
        let buff = event.target.value;
        // setState({roomNumber: buff});
    }
    const uploadHandleChange = (event) => {
        const filename = event.target.value;
        if (filename == ''){
            console.log("No file");
            return;
        }
        s3upload(filename, event.target.files[0], event.target.name);
    }

    function s3upload(name, file, type) {
        const filePath = location.state.id + "/" + type + "/" + name.substr(name.lastIndexOf("\\") + 1);
        console.log(filePath);
        Storage.put(filePath, file, {
            level: 'private'
        }).then(result => {
            console.log(result);
            alert(filePath + " アップロード完了");
        }).catch(err => {
            console.log(err);
            alert(err);
        });
    }
    function constructor(props) {
        // super(props);
        setLockInfo();
        // state=lockInfo;
        lockHandleClick = lockHandleClick.bind(this)
        gatewayHandleClick = gatewayHandleClick.bind(this)
    }
    function getLockLinks(state){
        var list = [];
        for(var i in state.locks){
            list.push(<li>
                <label class="li_left"><button class="link_style_button" onClick={lockHandleClick.bind(this, state.locks[i].serial_number)}>{state.locks[i].serial_number}</button></label>
                <label class="li_left">{state.locks[i].floor}</label>
                <label class="li_left">{state.locks[i].name}</label>
                </li>
                );
            list.push(<br></br>);
        }
        return list;
    }
    function lockHandleClick(locksData){
        history.push({
          pathname: "/setting_doorlock",
          state: { text: locksData }
      });
    }

    function getGatewayLinks(state){
        var list = [];
        for(var i in state.gws){
            list.push(<li>
                <label class="li_left"><button class="link_style_button" onClick={gatewayHandleClick.bind(this, state.gws[i].serial_number)}>{state.gws[i].serial_number}</button></label>
                <label class="li_left">{state.gws[i].floor}</label>
                <label class="li_left">-</label>
                </li>
                );
            list.push(<br></br>);
        }
        return list;
    }
    function gatewayHandleClick(gatewayData){
        history.push({
          pathname: "/setting_gateway",
          state: { text: gatewayData }
      });
    }
    setLockInfo();
    // 電子錠一覧のリンクを取得
    var lockLinks = getLockLinks(testJson);
    var gatewayLinks = getGatewayLinks(testJson);

        return (
            <div>
                <p>{location.state.text}　機器一覧</p><br></br>
                <form>
                    <ul>
                        <li>
                            <label class="li_left">ドアロック</label>
                            <label class="file_upload_button_label">登録<input class="file_upload_button" type='file' accept=".csv" name="ddl" onChange={uploadHandleChange} /></label>
                            <input class="button" type="submit" value="削除" />
                        </li>
                        <li>
                            <label class="li_left">Serial Number</label>
                            <label class="li_left">Floor</label>
                            <label class="li_left">roomNumber</label>
                        </li>
                        <br></br>
                        {/* ここから電子錠一覧のリンクのリストが表示される */}
                        {lockLinks}
                        {/* ここまで電子錠一覧のリンクのリストが表示される */}
                    </ul>
                </form>
                <br></br>
                <br></br>
                <form>
                    <ul>
                        <li>
                            <label class="li_left">ゲートウェイ</label>
                            <label class="file_upload_button_label">登録<input class="file_upload_button" type='file' accept=".csv" name="gw" onChange={uploadHandleChange} /></label>
                            <input class="button" type="submit" value="削除" />
                        </li>
                        <li>
                            <label class="li_left">Serial Number</label>
                            <label class="li_left">Floor</label>
                            <label class="li_left">roomNumber</label>
                        </li>
                        <br></br>
                        {/* ここからゲートウェイ一覧のリンクのリストが表示される */}
                        {gatewayLinks}
                        {/* ここまでゲートウェイ一覧のリンクのリストが表示される */}
                    </ul>
                </form>
                <br></br>
                <br></br>
                <form>
                    <ul>
                        <li>
                            <label class="li_left">共用部</label>
                            <input class="button" type="submit" value="登録" />
                            <input class="button" type="submit" value="削除" />
                        </li>
                        <li>
                            <label class="li_left">Topic</label>
                        </li>
                        <br></br>
                        <li>
                            <label class="li_left"><Link class="li_left" to={`/`}>/lead001/</Link></label>
                        </li>
                    </ul>
                </form>
            </div>
        );
}

export default Device_list;
