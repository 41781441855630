import React from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import '../App.css';
import testJson from '.././test/testJson/contractorTest.json';
import { useState } from 'react';


function Contractor(){
    const location = useLocation();
    function handleSubmit(e) {
        e.preventDefault();
        console.log('You clicked submit.');
        // alert(state.roomNumber);
    }
    var contractorsInfo = null;
    function setContractorsInfo(){
        contractorsInfo = testJson[0];
    }

    var handleChange = (event) => {
        let buff = event.target.value;
        // setState({roomNumber: buff});
    }

    function constructor(props) {
        // super(props);
        setContractorsInfo();
        // state=contractorsInfo;
        handleClick = handleClick.bind(this)
    }
    
    function getContractorsLinks(state){
        var list = [];
        for(var i in state.contractors){
            list.push(<li>
                <button class="link_style_button" onClick={handleClick.bind(this, state.contractors[i].name)}>{state.contractors[i].name}</button>
                </li>);
        }
        return list;
    }
    const history = useHistory();
    function handleClick(contractorData){
        history.push({
          pathname: "/hotellist",
          state: { text: contractorData }
      });
    }

        // 契約者の情報を取得
        setContractorsInfo();
        // 契約者一覧のリンクを取得
        var links = getContractorsLinks(testJson);
        return (
            <div>
                契約者一覧<br></br>
                <form >
                    <ul>
                        <li>
                            <label class="li_left">契約者一覧</label>
                            {/* <input class="button" type="button" value="新規登録" onclick="location.href='/regist_contractor'"/> */}
                            <Link　class="button" to={`/regist_contractor`}>新規登録</Link>
                        </li>
                        {/* ここから契約者一覧のリンクのリストが表示される */}
                        {links}
                        {/* ここまで契約者一覧のリンクのリストが表示される */}
                    </ul>
                </form>


            </div>
        );
}
 
export default Contractor;