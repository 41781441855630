import React from 'react';

class page2 extends React.Component {

    render() {
        return (
            <div>
                HelloPage2!!!
            </div>
        );
    }
}

export default page2;   