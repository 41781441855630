import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import testJson from '.././test/testJson/test.json';
import { useState } from 'react'


class usermanagement extends React.Component {
    handleSubmit(e) {
        e.preventDefault();
        console.log('You clicked submit.');
        alert(this.state.roomNumber);
    }
    lockInfo = null;
    setLockInfo(){
        this.lockInfo = testJson[0];
    }

    handleChange = (event) => {
        let buff = event.target.value;//入力された文字列
        this.setState({roomNumber: buff});
    }




    constructor(props) {
        super(props);
        this.setLockInfo();
        this.state=this.lockInfo;
    }
    

    render() {
        this.setLockInfo();
        return (
            <div>
                ユーザー管理<br></br>
                <form>
                    <ul>
                        <li>
                            <label class="li_left">ユーザー管理</label>
                            <Link　class="button" to={`/regist_user`}>新規登録</Link>

                        </li>
                        <li>
                            <Link to={`/`}>ユーザー１</Link>
                        </li>
                        <li>
                          　<Link to={`/`}>ユーザー２</Link>
                        </li>
                    </ul>
                </form>


            </div>
        );
    }
}
 
export default usermanagement; 