import React,{useEffect} from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import '../App.css';
import testJson from '.././test/testJson/setting_gateway_test.json';
import testJson0 from '.././test/testJson/setting_gateway_test0.json';
import { useState } from 'react'

// function handleSubmit(e) {
//     e.preventDefault();
//     console.log('You clicked submit.');
//     alert(state.roomNumber);
// }

function Setting_gateway() {
    const location = useLocation();
    const [gatewayInfo, setGatewayInfo] = useState(testJson0);
    function handleSubmit(e) {
        e.preventDefault();
        console.log('You clicked submit.');
        // alert(state.roomNumber);
    }
    useEffect(() => {
        async function fetchGatewayInfoAsync() {
            // const resp = await fetchGatewayInfoAsync();
            const resp = await testJson;
            setGatewayInfo(resp);
        }
        fetchGatewayInfoAsync();
    },[]);

    // function setGatewayInfo(){
    //     gatewayInfo = testJson[0];
    // }
    const history = useHistory();
    var handleChange = (event) => {
        let buff = event.target.value;//入力された文字列
        // setState({roomNumber: buff});
    }
    function constructor(props) {
        // super(props);
        setGatewayInfo();
        // state={
        //     "id" : "0",
        //     "dayTime" : "2000/1/1 00:00:00",
        //     "serialNumber" : "0",
        //     "dskCode" : "0",
        //     "gateway" : "GWV0-0",
        //     "floor" : "0F",
        //     "roomNumber" : "0",
        //     "battery" : "0%",
        //     "abnormalityNotification" : "",
        //     "status" : "未取得",
        //     "RSSI" : "0"
        // };
        // state=gatewayInfo;
    }
    

        // setGatewayInfo();
        return (
            <div>
                gateway{location.state.text}<br></br>
                <form>
                    <ul>
                        <li>
                            <label class="li_left">情報取得日時</label>
                        </li>
                        <li>
                            <input class="textbox" placeholder={gatewayInfo.last_keepalive_time} type="text" disabled/>
                            <input class="button" type="submit" value="更新" />
                        </li>
                        <li>
                        </li>
                    </ul>
                </form>
                <form>
                    <ul>
                        <li>
                            <label class="li_left">接続ゲートウェイ</label>
                            <input class="textbox" placeholder={gatewayInfo.gateway} type="text" disabled/>
                        </li>
                        <li>
                            <label class="li_left">設置フロア</label>
                            <input class="textbox" placeholder={gatewayInfo.floor} type="text"/>
                            <input class="button" type="submit" value="登録" />
                        </li>
                    </ul>
                </form>
                <ul>
                    <li>
                        <label class="li_left">状態表示</label>
                        <input class="textbox" placeholder={gatewayInfo.status} type="text" disabled/>
                    </li>
                </ul>
                <form>
                    <ul>
                        <li>
                            <label class="li_left">インクルージョン</label>
                            <input class="button" type="submit" value="実行" />
                        </li>
                    </ul>
                </form>
                <form>
                    <ul>
                        <li>
                            <label class="li_left">エクスクルージョン</label>
                            <input class="button" type="submit" value="実行" />
                        </li>
                    </ul>
                </form>
            </div>
        );
}
 
export default Setting_gateway; 